<template>
  <b-overlay :show="show">
    <div class="my-register-page" style="font-size: 13px">
      <div class="my-container">
        <div class="cardStyleEdit mb-3 mt-4 pt-2">
          <b-row>
            <b-col>
              <h5 class="styleText">1 - {{ $t("punkt") }}</h5>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="12" sm="12" md="6">
              <custom-input
                disabled
                :placeholder="$t('orderCode')"
                v-model="Request.orderCode"
                :label="$t('orderCode')"
              ></custom-input>
            </b-col>
          </b-row>
        </div>
        <div class="cardStyleEdit mb-3">
          <b-row>
            <b-col>
              <h5 class="styleText">2 - {{ $t("punkt") }}</h5>
            </b-col>
          </b-row>
          <!-- <b-table-simple bordered responsive>
            <b-tbody>
              <b-tr variant="primary">
                <b-td colspan="4" class="text-center">
                  <span style="font-size: 18px; font-weight: bold">{{
                    $t("DocumentInfo")
                  }}</span>
                </b-td>
              </b-tr>
              <b-tr>
                <b-td style="font-weight:bold">
                  {{ $t("inn") }}
                </b-td>
                <b-td>
                  {{ orgInfo.contractor.innOrPinfl }}
                </b-td>
                <b-td style="font-weight:bold">
                  {{ $t("name") }}
                </b-td>
                <b-td>
                  {{ orgInfo.contractor.fullName }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-td style="font-weight:bold">
                  {{ $t("oblast") }}
                </b-td>
                <b-td>
                  {{ orgInfo.contractor.region }}
                </b-td>
                <b-td style="font-weight:bold">
                  {{ $t("region") }}
                </b-td>
                <b-td>
                  {{ orgInfo.contractor.district }}
                </b-td>
              </b-tr>
              <b-tr>
                <b-td style="font-weight:bold">
                  {{ $t("oked") }}
                </b-td>
                <b-td >
                  {{ orgInfo.contractor.oked }}
                </b-td>
                 <b-td style="font-weight:bold">
                  {{ $t("fullname") }}
                </b-td>
                <b-td>
                  {{ orgInfo.fullName }}
                </b-td>
              </b-tr>             
            </b-tbody>
          </b-table-simple> -->
          <b-row class="mt-3">
            <b-col cols="12" sm="6" md="6" class="mb-3">
              <v-select
                disabled
                :placeholder="$t('orderedOrganization')"
                class="my-select"
                :options="ParentList"
                :reduce="(item) => item.value"
                label="text"
                v-model="Request.orderedOrganizationId"
                @input="ChangeOrganization"
              ></v-select>
            </b-col>
            <b-col cols="12" sm="6" md="6" class="mb-3">
              <v-select
                disabled
                :placeholder="$t('inspectionOrganization')"
                class="my-select"
                :options="OrganizationList"
                :reduce="(item) => item.value"
                label="text"
                v-model="Request.inspectionOrganizationId"
                @input="ChangeInspectionOrganization"
              ></v-select>
            </b-col>
            <b-col cols="12" sm="12" md="6">
              <custom-input-white
                disabled
                :placeholder="$t('inspectionOrganizationAddres')"
                v-model="Request.contractorAddres"
                :label="$t('inspectionOrganizationAddres')"
              ></custom-input-white>
            </b-col>
            <b-col cols="12" sm="12" md="6">
              <custom-input-white
                disabled
                :placeholder="$t('inspectionOrganizationName')"
                v-model="Request.inspectionOrganizationName"
                :label="$t('inspectionOrganizationName')"
              ></custom-input-white>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="12" sm="12" md="6">
              <custom-input-white
                disabled
                :placeholder="$t('inspectionOrganizationPostalAddres')"
                v-model="Request.inspectionOrganizationAddres"
                :label="$t('inspectionOrganizationPostalAddres')"
              ></custom-input-white>
            </b-col>
            <b-col cols="12" sm="12" md="6">
              <custom-input-white
                disabled
                :placeholder="$t('inspectionOrganizationCeo')"
                v-model="Request.inspectionOrganizationCeo"
                :label="$t('inspectionOrganizationCeo')"
              ></custom-input-white>
            </b-col>
          </b-row>
        </div>
        <div class="cardStyleEdit mb-3">
          <b-row>
            <b-col class="mt-2 mb-2" style="text-align: left">
              <h5 class="styleText">3 - {{ $t("punkt") }}</h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-table-simple bordered responsive>
                <b-tbody>
                  <b-tr variant="primary">
                    <b-td colspan="6" class="text-center">
                      <span style="font-size: 18px; font-weight: bold">{{
                        $t("inspectors")
                      }}</span>
                    </b-td>
                  </b-tr>
                  <b-tr variant="primary">
                    <b-td>
                      <span style="font-size: 14px; font-weight: bold">
                        {{ $t("employeeName") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("dutyNumberInspector1") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("dutyExpirationInspector") }}
                      </span>
                    </b-td>
                    <b-td>
                      <span style="font-size: 14px; font-weight: bold">
                        {{ $t("certificateNumber1") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("certificateExpiration1") }}
                      </span>
                    </b-td>
                  </b-tr>
                  <b-tr
                    style="background-color: white"
                    v-for="(item, index) in Request.inspectors"
                    :key="index"
                  >
                    <b-td>
                      {{ item.fullName }}
                    </b-td>
                    <b-td class="text-right">
                      <!-- <custom-input-white
                        :label="$t('')"
                        :disabled="Request.statusId == 2"
                        :placeholder="$t('dutyNumberInspector')"
                        v-model="item.dutyNumber"
                      ></custom-input-white> -->
                      <p>{{ item.dutyNumber }}</p>
                    </b-td>
                    <b-td>
                      <!-- <custom-date-picker
                        :disabled="Request.statusId == 2"
                        :label="$t('dutyExpirationInspector')"
                        placeholder="00.00.0000"
                        v-model="item.dutyExpiration"
                      ></custom-date-picker> -->
                      <p>{{ item.dutyExpiration }}</p>
                    </b-td>
                    <b-td class="text-right">
                      <!-- {{ item.certificateNumber }} -->
                      <!-- <custom-input-white
                        :label="$t('')"
                        :disabled="Request.statusId == 2"
                        :placeholder="$t('certificateNumber')"
                        v-model="item.certificateNumber"
                      ></custom-input-white> -->
                      <p>{{ item.certificateNumber }}</p>
                    </b-td>
                    <b-td>
                      <!-- {{ item.certificateExpiration }} -->
                      <!-- <custom-date-picker
                        :disabled="Request.statusId == 2"
                        :label="$t('certificateExpiration')"
                        placeholder="00.00.0000"
                        v-model="item.certificateExpiration"
                      ></custom-date-picker> -->
                      <p>{{ item.certificateExpiration }}</p>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
        </div>
        <div class="cardStyleEdit mb-3">
          <b-row>
            <b-col class="mt-2 mb-2" style="text-align: left">
              <h5 class="styleText">4 - {{ $t("punkt") }}</h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-table-simple bordered responsive>
                <b-tbody>
                  <b-tr variant="primary">
                    <b-td colspan="10" class="text-center">
                      <span style="font-size: 18px; font-weight: bold">{{
                        $t("involvedInspectors")
                      }}</span>
                    </b-td>
                  </b-tr>
                  <b-tr variant="primary">
                    <b-td>
                      <span style="font-size: 14px; font-weight: bold">
                        {{ $t("organizationName") }}
                      </span>
                    </b-td>
                    <b-td>
                      <span style="font-size: 14px; font-weight: bold">
                        {{ $t("addressEmail") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("inspectorFullName") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("dutyNumberInspector") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("dutyExpirationInspector") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("dutyIssuingOrganization") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("certificateNumber") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("certificateIssuingOrganization") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("certificateExpiration") }}
                      </span>
                    </b-td>
                  </b-tr>
                  <b-tr
                    style="background-color: white"
                    v-for="(item, index) in Request.involvedInspectors"
                    :key="index"
                  >
                    <b-td>
                      {{ item.organizationName }}
                    </b-td>

                    <b-td>
                      {{ item.address }}
                    </b-td>
                    <b-td>
                      {{ item.inspectorFullName }}
                    </b-td>

                    <b-td>
                      {{ item.dutyNumber }}
                    </b-td>
                    <b-td>
                      {{ item.dutyExpiration }}
                    </b-td>

                    <b-td>
                      {{ item.dutyIssuingOrganization }}
                    </b-td>
                    <b-td>
                      {{ item.certificateNumber }}
                    </b-td>

                    <b-td>
                      {{ item.certificateIssuingOrganization }}
                    </b-td>
                    <b-td>
                      {{ item.certificateExpiration }}
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-table-simple bordered responsive>
                <b-tbody>
                  <b-tr variant="primary">
                    <b-td colspan="8" class="text-center">
                      <span style="font-size: 18px; font-weight: bold">{{
                        $t("InvolvedAuditors")
                      }}</span>
                    </b-td>
                  </b-tr>
                  <b-tr variant="primary">
                    <b-td>
                      <span style="font-size: 14px; font-weight: bold">
                        {{ $t("organizationNameAuditor") }}
                      </span>
                    </b-td>
                    <b-td>
                      <span style="font-size: 14px; font-weight: bold">
                        {{ $t("licenseDate") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("licenseNumber") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("licenseExpiration") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("auditorFullName") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("contractNumber") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("contractExpiration") }}
                      </span>
                    </b-td>
                  </b-tr>
                  <b-tr
                    style="background-color: white"
                    v-for="(item, index) in Request.involvedAuditors"
                    :key="index"
                  >
                    <b-td>
                      {{ item.organizationName }}
                    </b-td>

                    <b-td>
                      {{ item.licenseDate }}
                    </b-td>
                    <b-td>
                      {{ item.licenseNumber }}
                    </b-td>
                    <b-td>
                      {{ item.licenseExpiration }}
                    </b-td>
                    <b-td>
                      {{ item.auditorFullName }}
                    </b-td>
                    <b-td>
                      {{ item.contractNumber }}
                    </b-td>
                    <b-td>
                      {{ item.contractExpiration }}
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <b-table-simple bordered responsive>
                <b-tbody>
                  <b-tr variant="primary">
                    <b-td colspan="5" class="text-center">
                      <span style="font-size: 18px; font-weight: bold">{{
                        $t("specialists")
                      }}</span>
                    </b-td>
                  </b-tr>
                  <b-tr variant="primary">
                    <b-td>
                      <span style="font-size: 14px; font-weight: bold">
                        {{ $t("inspectorsName") }}
                      </span>
                    </b-td>
                    <b-td>
                      <span style="font-size: 14px; font-weight: bold">
                        {{ $t("contractNumber") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("identificationDocument") }}
                      </span>
                    </b-td>
                    <b-td
                      ><span style="font-size: 14px; font-weight: bold">
                        {{ $t("contractExpiration") }}
                      </span>
                    </b-td>
                  </b-tr>
                  <b-tr
                    style="background-color: white"
                    v-for="(item, index) in Request.specialists"
                    :key="index"
                  >
                    <b-td>
                      {{ item.fullName }}
                    </b-td>

                    <b-td>
                      {{ item.contractNumber }}
                    </b-td>
                    <b-td>
                      {{ item.identificationDocument }}
                    </b-td>

                    <b-td>
                      {{ item.contractExpiration }}
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
        </div>
        <div class="cardStyleEdit mb-3">
          <b-row>
            <b-col>
              <h5 class="styleText">5 - {{ $t("punkt") }}</h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" sm="12" md="4" class="mb-3">
              <custom-input-white
                class="inputStyle"
                disabled
                v-mask="'###-###-###'"
                :placeholder="$t('docNumber5')"
                v-model="Request.docNumber"
                :label="$t('docNumber5')"
              ></custom-input-white>
            </b-col>
            <b-col cols="12" sm="12" md="4" class="mb-3">
              <custom-input-white
                class="inputStyle"
                disabled
                :placeholder="$t('orderNumber5')"
                v-model="Request.orderNumber"
                :label="$t('orderNumber5')"
              ></custom-input-white>
            </b-col>
            <b-col cols="12" sm="12" md="4" class="mb-3">
              <custom-date-picker
                disabled
                :label="$t('orderDate5')"
                placeholder="00.00.0000"
                v-model="Request.orderDate"
              ></custom-date-picker>
            </b-col>
            <b-col cols="12" sm="12" md="12">
              <custom-textarea
                class="inputStyle"
                disabled
                :placeholder="$t('criminalCaseInfo')"
                v-model="Request.criminalCaseInfo"
                :label="$t('criminalCaseInfo')"
              ></custom-textarea>
            </b-col>
          </b-row>
        </div>
        <div class="cardStyleEdit mb-3">
          <b-row>
            <b-col>
              <h5 class="styleText">6 - {{ $t("punkt") }}</h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" sm="12" md="12">
              <v-select
                disabled
                multiple
                :placeholder="$t('controlFunctions')"
                class="my-select"
                :options="ControlFunctionList"
                :reduce="(item) => item.value"
                label="text"
                v-model="Request.controlFunctionNames"
                @input="ControlFunctionsChange"
              ></v-select>
            </b-col>
          </b-row>
        </div>
        <div class="cardStyleEdit mb-3">
          <b-row>
            <b-col>
              <h5 class="styleText">7 - {{ $t("punkt") }}</h5>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <!-- <b-col cols="12" sm="12" md="4">
              <custom-date-picker
                :disabled="
                  Request.statusId == 2 ||
                    $route.query.contractor == true ||
                    $route.query.contractor == 'true'
                "
                :label="$t('docDate')"
                placeholder="00.00.0000"
                v-model="Request.docDate"
              ></custom-date-picker>
            </b-col> -->

            <b-col cols="12" sm="12" md="4">
              <custom-date-picker
                disabled
                :label="$t('startDate')"
                placeholder="00.00.0000"
                v-model="Request.startDate"
              ></custom-date-picker>
            </b-col>

            <b-col cols="12" sm="12" md="4">
              <custom-date-picker
                disabled
                :label="$t('endDate')"
                placeholder="00.00.0000"
                v-model="Request.endDate"
              ></custom-date-picker>
            </b-col>

            <b-col cols="12" sm="12" md="4">
              <custom-input-white
                disabled
                placeholder="0"
                v-model="Request.checkDaysNumber"
                :label="$t('checkDaysNumber')"
              ></custom-input-white>
            </b-col>
          </b-row>
        </div>
        <div class="cardStyleEdit mb-3">
          <b-row>
            <b-col>
              <h5 class="styleText">8 - {{ $t("punkt") }}</h5>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <!-- <b-col cols="12" sm="12" md="4">
              <custom-date-picker
                :disabled="
                  Request.statusId == 2 ||
                    $route.query.contractor == false ||
                    $route.query.contractor == 'false'
                "
                :label="$t('docDate')"
                placeholder="00.00.0000"
                v-model="Request.docDate"
              ></custom-date-picker>
            </b-col> -->

            <b-col cols="12" sm="12" md="4">
              <custom-date-picker
                disabled
                :label="$t('contractorStartDate')"
                placeholder="00.00.0000"
                v-model="Request.contractorStartDate"
              ></custom-date-picker>
            </b-col>

            <b-col cols="12" sm="12" md="4">
              <custom-date-picker
                disabled
                :label="$t('contractorEndDate')"
                placeholder="00.00.0000"
                v-model="Request.contractorEndDate"
              ></custom-date-picker>
            </b-col>
          </b-row>
        </div>
        <div class="cardStyleEdit mb-3">
          <b-row>
            <b-col>
              <h5 class="styleText">9 - {{ $t("punkt") }}</h5>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="12" sm="12" md="4">
              <custom-input-white
                disabled
                placeholder="0"
                v-model="Request.contractorCheckDaysNumber"
                :label="$t('ContractorCheckDaysNumber')"
              ></custom-input-white>
            </b-col>
          </b-row>
        </div>
        <div class="cardStyleEdit mb-3">
          <b-row>
            <b-col>
              <h5 class="styleText">10 - {{ $t("punkt") }}</h5>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="12" sm="12" md="12">
              <custom-textarea
                disabled
                :placeholder="$t('requestedDocuments')"
                v-model="Request.requestedDocuments"
                :label="$t('requestedDocuments')"
              ></custom-textarea>
            </b-col>
          </b-row>
        </div>
        <div class="cardStyleEdit mb-3">
          <b-row>
            <b-col>
              <h5 class="styleText">11 - {{ $t("punkt") }}</h5>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="12" sm="12" md="12">
              <custom-textarea
                disabled
                :placeholder="$t('takenMeasures')"
                v-model="Request.takenMeasures"
                :label="$t('takenMeasures')"
              ></custom-textarea>
            </b-col>
          </b-row>
        </div>
        <div class="cardStyleEdit mb-3">
          <b-row>
            <b-col>
              <h5 class="styleText">12 - {{ $t("punkt") }}</h5>
            </b-col>
          </b-row>
          <b-row class="mt-3">
            <b-col cols="12" sm="12" md="12">
              <custom-textarea
                disabled
                :placeholder="$t('thirdPartyInfo')"
                v-model="Request.thirdPartyInfo"
                :label="$t('thirdPartyInfo')"
              ></custom-textarea>
            </b-col>
          </b-row>
        </div>
        <div class="cardStyleEdit mb-3">
          <b-row>
            <b-col>
              <h5 class="styleText">{{ $t("comment") }}</h5>
            </b-col>
          </b-row>
          <b-row class="my-3">
            <b-col cols="12" sm="12" md="12">
              <custom-textarea
                disabled
                :placeholder="$t('comment')"
                v-model="Request.comment"
                :label="$t('comment')"
              ></custom-textarea>
            </b-col>
            <b-col class="mt-2" cols="12" sm="12" md="4">
              <b-form-rating
                disabled
                v-model="Request.rate"
                variant="warning"
              ></b-form-rating>
            </b-col>
          </b-row>
        </div>
        <b-row class="mb-3">
          <b-col col="6">
            <b-button
              @click="back"
              style="width: 150px"
              variant="outline-danger"
            >
              {{ $t("back") }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import InspectionBookService from "@/services/inspectionbook.service";
import customDatePicker from "../../components/customDatePickerWhite.vue";
import customInput from "../../components/elements/customInput.vue";
import customInputWhite from "../../components/customInputWhite.vue";
import customSelect from "../../components/customSelectWhite.vue";
import vSelect from "vue-select";
import "bootstrap-vue/dist/bootstrap-vue.css";
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BBadge,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
  BOverlay,
  BFormRating,
  BModal,
  BLink,
} from "bootstrap-vue";
import customTextarea from "../../components/customTextareaWhite.vue";
import InspectorService from "@/services/inspector.service";
import ControlFunctionService from "@/services/controlfunction.service";
import ManualService from "@/services/manual.service";
export default {
  data() {
    return {
      isLoading: false,
      Request: {},
      InspectionBookOfContractor: {},
      InspectionResult: {},
      RequestTable: [],
      InspectorsList: [],
      ControlFunctionList: [],
      filter: {},
      show: false,
      items: [],
      filtesIns: {
        parentOrganizationId: 0,
        organizationId: 0,
        isOnlyRegistered: true,
        isHr: false,
        isPassedAttestation: true,
        isInitQuery: true,
        sortBy: "",
        orderType: "asc",
        page: 1,
        pageSize: 2000,
      },
      modal: false,
      modalInspectors: false,
      modalAuditors: false,
      modalInspectorsInfo: false,
      filters: {
        orderedOrganizationId: 0,
      },
      isSave: false,
      tabrow: {
        id: 0,
        inspectionBookId: 0,
        fullName: "",
        contractNumber: "",
        identificationDocument: "",
        contractExpiration: "",
        // Status: 0,
      },
      tabrowInspectors: {
        id: 0,
        inspectionBookId: 0,
        organizationName: "",
        address: "",
        inspectorFullName: "",
        dutyNumber: "",
        dutyExpiration: "",
        dutyIssuingOrganization: "",
        certificateNumber: "",
        certificateIssuingOrganization: "",
        certificateExpiration: "",
      },
      tabrowAuditors: {
        id: 0,
        inspectionBookId: 0,
        organizationName: "",
        licenseDate: "",
        licenseNumber: "",
        licenseExpiration: "",
        auditorFullName: "",
        contractNumber: "",
        contractExpiration: "",
      },
      filter1: {
        page: 1,
        pageSize: 10,
        totalRows: 1,
        search: "",
      },
      fields: [
        {
          key: "isSelected",
          label: this.$t("isSelected"),
        },
        { key: "employeeId", label: "ID", sort: true },
        { key: "fullName", label: this.$t("fullname"), sort: true },
      ],
      editedIndex1: -1,
      editedIndex: -1,
      editedIndex2: -1,
      orgInfo: "",
      ParentList: [],
      OrganizationList: [],
    };
  },
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BBadge,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BOverlay,
    customDatePicker,
    customInput,
    BFormRating,
    customTextarea,
    vSelect,
    BModal,
    BLink,
    customInputWhite,
    customSelect,
  },
  created() {
    this.orgInfo = JSON.parse(localStorage.getItem("user_info"));
    this.Refresh();

    ManualService.GetOrganizationAsSelectList("", false, true).then((res) => {
      this.ParentList = res.data;
    });
    this.addInspectorList();
    this.ControlFunctionsChange();
  },
  methods: {
    ChangeOrganization() {
      this.Request.inspectionOrganizationId = 0;
      this.Request.inspectionOrganizationName = "";
      ManualService.GetOrganizationAsSelectList(
        this.Request.orderedOrganizationId,
        false,
        true
      ).then((res) => {
        this.OrganizationList = res.data;
      });
    },
    ChangeInspectionOrganization() {
      this.Request.inspectionOrganizationName = "";
      if (!!this.Request.inspectionOrganizationId) {
        this.Request.inspectionOrganizationName = this.OrganizationList.filter(
          (item) => item.value === this.Request.inspectionOrganizationId
        )[0].text;
        ControlFunctionService.GetAsSelectList(
          this.Request.orderedOrganizationId
        ).then((res) => {
          this.ControlFunctionList = res.data;
        });
      }
    },
    // AddNewInspectors() {
    //   this.modalInspectorsInfo = true;
    //   this.isLoading = true;
    //   InspectorService.GetAsSelectList({
    //     parentOrganizationId: this.Request.orderedOrganizationId,
    //     organizationId: this.Request.inspectionOrganizationId,
    //     isHr: false,
    //     isPassedAttestation: true,
    //     search: this.filter1.search,
    //     sortBy: this.filter1.sortBy,
    //     orderType: this.filter1.orderType,
    //     page: this.filter1.page,
    //     pageSize: this.filter1.pageSize,
    //   }).then((res) => {
    //     // this.items = res.data.rows;
    //     var ids = this.RequestTable.map((item) => item.employeeId);
    //     this.items = [];
    //     for (let i = 0; i < res.data.rows.length; i++) {
    //       this.items.push({
    //         fullName: res.data.rows[i].text,
    //         employeeId: res.data.rows[i].value,
    //         isSelected: ids.includes(res.data.rows[i].value),
    //       });
    //     }
    //     this.filter1.totalRows = res.data.total;
    //     // this.addInspectorList()
    //     this.isLoading = false;
    //   });
    // },
    // addInspectorList() {
    //   var ids = this.RequestTable.map((item) => item.employeeId);
    //   this.items = [];
    //   for (let i = 0; i < this.items.length; i++) {
    //     this.items.push({
    //       fullName: this.items[i].text,
    //       id: this.items[i].value,
    //       isSelected: ids.includes(this.items[i].value),
    //     });
    //   }
    //   this.filter1.totalRows = res.data.total;
    // },

    ControlFunctionsChange() {
      this.Request.controlFunctions = this.filter.ControlFunctionList(
        (item) => item.value === this.Request.controlFunctionNames
      )[0];
    },
    AddOked() {
      if (this.editedIndex1 > -1) {
        Object.assign(this.Request.specialists[this.editedIndex1], this.tabrow);
      } else {
        this.Request.specialists.push(this.tabrow);
      }
      this.modal = false;
      this.tabrow = {};
    },

    Refresh() {
      this.show = true;
      InspectionBookService.Get(this.$route.params.id)
        .then((res) => {
          this.Request = res.data;
          this.show = false;
          ManualService.GetOrganizationAsSelectList(
            this.Request.orderedOrganizationId,
            false,
            true
          ).then((res) => {
            this.OrganizationList = res.data;
          });
          if (!!this.Request.inspectionOrganizationId) {
            this.Request.inspectionOrganizationName = this.OrganizationList.filter(
              (item) => item.value === this.Request.inspectionOrganizationId
            )[0].text;
            ControlFunctionService.GetAsSelectList(
              this.Request.orderedOrganizationId
            ).then((res) => {
              this.ControlFunctionList = res.data;
            });
          }
        })
        .catch((error) => {
          this.makeToast(error.response.data, "error");
          this.show = false;
        });
    },
    back() {
      // this.$router.push({ name : 'inspectionbookofcontractor'})
      this.$router.go(-1);
    },

    makeToast(message, type) {
      var a = "";
      if (message.status == 500) {
        a = message.title;
      }
      if (message.status == 400) {
        var errors = Object.values(message.errors);
        var a = errors.map((el, item) => item + 1 + "." + el).join("\n");
      } else {
        a = message;
      }
      this.$toast.open({
        message: a,
        type: type,
        duration: 5000,
        dismissible: true,
      });
    },
  },
};
</script>

<style>
.backColor {
  background-color: #f3f1f1 !important;
}
.vs__dropdown-toggle {
  background-color: white !important;
}
.v-toast__item--Error {
  background-color: red;
}
.v-toast__item--success {
  background-color: green;
}
.cardStyleEdit {
  padding: 5px;
  background-color: #eaf2ff;
  border-radius: 5px;
}
.styleText {
  font-weight: 900;
}
.my-select .vs__dropdown-toggle {
  padding: 12px 10px !important;
  border: none;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: #f7f7f7;
}
</style>
